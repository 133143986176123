.hoverContainer {
  position: relative;
  background-color: #2962FF;
}

.content {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.modal {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.hoverContainer:hover .content {
  opacity: 0.25;
}

.hoverContainer:hover .modal {
  opacity: 1.0;
  z-index: 10;
}

.innerContainer {
  padding: 16px 32px;
  box-sizing: margin-box;

}